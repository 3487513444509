<template>
  <div>
    <label>级联选择:仅返回真实选择测试</label>
    <el-cascader
        v-model="selectedOptions"
        :options="myOptions"
        :props="myProps"
        placeholder="请选择"
        @change="handleChange"
    ></el-cascader>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedOptions: [],
      trueSelectOptions: [],
      myOptions: [
        {
          value: 'zhinan',
          label: '指南',
          children: [
            {
              value: 'shejiyuanze',
              label: '设计原则',
              children: [
                {
                  value: 'yizhi',
                  label: '一致'
                },
                {
                  value: 'fankui',
                  label: '反馈'
                },
                {
                  value: 'xiaolv',
                  label: '效率'
                },
                {
                  value: 'kekong',
                  label: '可控',
                  children: [
                    {
                      value: '1ke1kong1',
                      label: '内部可控'
                    },
                    {
                      value: '2ke2kong2',
                      label: '外部可控'
                    }
                  ]
                }
              ]
            },
            {
              value: 'daohang',
              label: '导航',
              children: [
                {
                  value: 'cexiangdaohang',
                  label: '侧向导航'
                },
                {
                  value: 'dingbudaohang',
                  label: '顶部导航'
                }
              ]
            }
          ]
        },
      ],
      myProps: {
        value: 'value',
        label: 'label',
        children: 'children',
        multiple: true,
      },
    };
  },
  methods: {
    handleChange(selectedValues) {
      // 处理真正勾选的节点
      let trueSelectedNodes = this.getTrueSelectedNodes(selectedValues, this.myOptions);
      console.log('真正勾选的节点:', trueSelectedNodes);
    },
    //获取真实选中的节点 返回真实选中数组
    getTrueSelectedNodes(selectedValues, options) {
      console.info(selectedValues)
      /*
      selectedValues 当前选中项是elementPlus返回的多维list
      {0: 'zhinan', 1: 'daohang', 2: 'cexiangdaohang'}
      {0: 'zhinan', 1: 'daohang', 2: 'dingbudaohang'}
      {0: 'zhinan', 1: 'shejiyuanze', 2: 'kekong', 3: '1ke1kong1'}
      {0: 'zhinan', 1: 'shejiyuanze', 2: 'kekong', 3: '2ke2kong2'
       */
      //options 是一个根的全部树状数据['zhinan','daohang'...]
      console.info(options)
      //使用前清空
      this.trueSelectOptions = [];
      //获取真实选中
      this.checkValidity(options, selectedValues)
      return this.trueSelectOptions;
    },
    //获取真实选中节点 返回状态
    checkValidity(tree, validList) {
      //多维数组扁平化处理
      const flatValidList = validList.flat();
      //遍历树状数据
      return tree.map(node => {
        //当前节点是否有效勾选
        let isValid = flatValidList.includes(node.value);

        if (node.children) {
          // 递归检查子节点
          const childValid = this.checkValidity(node.children, validList);
          // 判断所有子节点必须有效
          const allChildrenValid = childValid.every(valid => valid.isValid);
          // 判断若任意节点无效
          const anyChildInvalid = childValid.some(valid => !valid.isValid);

          // 如果所有子节点有效，当前节点有效；如果有任意子节点无效，当前节点无效
          isValid = isValid && allChildrenValid && !anyChildInvalid;
        }

        // console.log(`${node.label}: ${isValid ? '有效' : '无效'}`);
        //确定节点有效 才加入到真实选择
        if (isValid) {
          this.trueSelectOptions.push(node.value)
        }
        // 返回节点的有效性
        return {isValid};
      });
    }
  }
};
</script>
